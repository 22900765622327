import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import LoginPage from "./components/Login";
import Signup from "./components/Signup";
import Alumnilist from "./components/Alumnilist.jsx";
const App = () => {
  const location = useLocation(); 

  
  const hideSidebarRoutes = ["/"];

  return (
    <>
      {!hideSidebarRoutes.includes(location.pathname) && <Sidebar />}

      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/Alumni" element={<Alumnilist/>}/>
      </Routes>
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
