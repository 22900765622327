import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx'; // Import the XLSX library

const Alumnilist = () => {
  const [alumni, setAlumni] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch alumni data from the API
  useEffect(() => {
    const fetchAlumniData = async () => {
      try {
        const response = await axios.get('https://bw-formalu.bsgindia.tech/api/alumni');
        setAlumni(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load alumni data');
        setLoading(false);
      }
    };

    fetchAlumniData();
  }, []);

  // Export to Excel function
  const exportToExcel = () => {
    // Prepare the data in a format that is suitable for Excel
    const formattedData = alumni.map((alumnus) => ({
      Name: alumnus.name,
      'Educational Qualification': alumnus.educationalQualification,
      Occupation: alumnus.occupation,
      Phone: alumnus.phone,
      Email: alumnus.email,
      State: alumnus.state,
      'Awards': alumnus.awards.map(award => `${award.name} (${award.year})`).join(', '),
      'Membership': alumnus.membership.join(', '),
      'Correspondence Address': `${alumnus.correspondenceAddress.home}, ${alumnus.correspondenceAddress.street}, ${alumnus.correspondenceAddress.district}`,
      'Permanent Address': `${alumnus.permanentAddress.home}, ${alumnus.permanentAddress.street}, ${alumnus.permanentAddress.district}`,
      'Aadhar Number': alumnus.aadharNumber,
      'Whatsapp': alumnus.whatsapp,
      'Date of Birth': new Date(alumnus.dob).toLocaleDateString(),
      'How Can Help': alumnus.howCanHelp,
    }));

    // Create a new workbook and add the data to it
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Alumni Data');

    // Write the workbook to a file
    XLSX.writeFile(wb, 'AlumniData.xlsx');
  };

  // If loading, display a loading message
  if (loading) {
    return <div className="text-center text-lg text-blue-500">Loading...</div>;
  }

  // If there's an error, display the error message
  if (error) {
    return <div className="text-center text-lg text-red-500">{error}</div>;
  }

  return (
    <div className="p-6 ml-72">
      <h1 className="text-3xl font-bold text-center mb-6">Alumni List</h1>
      
      {/* Export to Excel Button */}
      <div className="text-center mb-4">
        <button
          onClick={exportToExcel}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          Export to Excel
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {alumni.map((alumnus) => (
          <div key={alumnus._id} className="bg-white shadow-lg rounded-lg p-4">
            <h2 className="text-xl font-semibold text-blue-600">{alumnus.name}</h2>
            <p className="text-sm text-gray-600">{alumnus.educationalQualification}</p>
            <p className="text-sm text-gray-600">{alumnus.occupation}</p>
            <p className="text-sm text-gray-600">Phone: {alumnus.phone}</p>
            <p className="text-sm text-gray-600">Email: {alumnus.email}</p>
            <p className="text-sm text-gray-600">State: {alumnus.state}</p>

            <div className="mt-4">
              <p className="font-semibold text-gray-700">Awards:</p>
              {alumnus.awards.map((award) => (
                <div key={award._id} className="text-sm text-gray-500">{award.name} ({award.year})</div>
              ))}
            </div>

            <div className="mt-4">
              <p className="font-semibold text-gray-700">Memberships:</p>
              {alumnus.membership.map((member, index) => (
                <div key={index} className="text-sm text-gray-500">{member}</div>
              ))}
            </div>

            <div className="mt-4">
              <p className="font-semibold text-gray-700">Correspondence Address:</p>
              <div className="text-sm text-gray-500">
                <p>{alumnus.correspondenceAddress.home}</p>
                <p>{alumnus.correspondenceAddress.street}</p>
                <p>{alumnus.correspondenceAddress.addressLine1}</p>
                <p>{alumnus.correspondenceAddress.addressLine2}</p>
                <p>{alumnus.correspondenceAddress.landmark}</p>
                <p>{alumnus.correspondenceAddress.pincode}</p>
                <p>{alumnus.correspondenceAddress.district}</p>
              </div>
            </div>

            <div className="mt-4">
              <p className="font-semibold text-gray-700">Permanent Address:</p>
              <div className="text-sm text-gray-500">
                <p>{alumnus.permanentAddress.home}</p>
                <p>{alumnus.permanentAddress.street}</p>
                <p>{alumnus.permanentAddress.addressLine1}</p>
                <p>{alumnus.permanentAddress.addressLine2}</p>
                <p>{alumnus.permanentAddress.landmark}</p>
                <p>{alumnus.permanentAddress.pincode}</p>
                <p>{alumnus.permanentAddress.district}</p>
              </div>
            </div>

            <div className="mt-4">
              <p className="font-semibold text-gray-700">Aadhar Number:</p>
              <p className="text-sm text-gray-500">{alumnus.aadharNumber}</p>
            </div>

            <div className="mt-4">
              <p className="font-semibold text-gray-700">Whatsapp Number:</p>
              <p className="text-sm text-gray-500">{alumnus.whatsapp}</p>
            </div>

            <div className="mt-4">
              <p className="font-semibold text-gray-700">Date of Birth:</p>
              <p className="text-sm text-gray-500">{new Date(alumnus.dob).toLocaleDateString()}</p>
            </div>

            <div className="mt-4">
              <p className="font-semibold text-gray-700">How Can Help:</p>
              <p className="text-sm text-gray-500">{alumnus.howCanHelp}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Alumnilist;
