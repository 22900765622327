import React from "react";
import { FaTachometerAlt } from "react-icons/fa";
import { BiSolidCategory } from "react-icons/bi";
import { FaAward } from "react-icons/fa6";
import { Link} from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="bg-[#1A2738] h-screen w-72 fixed shadow-lg flex flex-col justify-between">
      <div className="overflow-y-auto">
        <div className="flex items-center mt-6 px-6 py-4">
          <div className="ml-4">
            <div className="text-2xl font-bold text-white leading-tight">
            Alumni Managment
            </div>
          </div>
        </div>

        <nav className="mt-8 ">
          <div className="text-xs font-semibold text-[#9DC33A] pl-8 mb-3 tracking-wider">
            OVERVIEW
          </div>

          <Link
            to="/Alumni"
            className="flex items-center text-white py-3 pl-8 pr-4 mt-2 hover:bg-[#2A3D56] rounded-md transition-all duration-300 shadow-md"
          >
            <BiSolidCategory className="text-lg" />
            <span className="ml-4 text-base font-medium">Alumni list</span>
          </Link>
         

          
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
