import React, { useState } from "react";

import backgroundImage from "../img/background.jpg";
import { Link, useNavigate } from "react-router-dom";
import image from "../img/shape.png";
import axios from "axios";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("https://bw-formalu.bsgindia.tech/api/v1/auth/login", {
        email,
        password,
      });

      if (response.data.token) {
        
        const setSessionItemWithExpiry = (key, value, expiryInMinutes) => {
          const now = new Date();
          const expiryTime = now.getTime() + expiryInMinutes * 60 * 1000;
          const item = {
            value,
            expiry: expiryTime,
          };
          sessionStorage.setItem(key, JSON.stringify(item));
        };
        setSessionItemWithExpiry("token", response.data.token, 60);       
        navigate("/Alumni");  
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "An error occurred.");
    }
  };

  return (
    <div className="relative h-screen">
    <div
      className="absolute inset-0"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    ></div>
  
    <div className="absolute inset-0 flex items-center justify-center">
    
      <div
        className="flex justify-center items-center"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "600px",
          height: "450px",
        }}
      >
        <div className="rounded-lg p-8 border-slate-700 bg-transparent bg-opacity-80 shadow-lg">
          <h2 className="text-2xl font-bold mb-4 text-center">Login</h2>
          {errorMessage && (
            <p className="text-red-500 text-sm ">{errorMessage}</p>
          )}
          <p className="text-black font-semibold mb-6 text-center">
          for a BSG Admin Account
          </p>
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your email"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your password"
              />
            </div>
            <button
              type="submit"
              className="w-full py-2 px-4 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600"
            >
              Login
            </button>
          </form>
          <p className="mt-6 text-lg text-gray-800 text-center">
            <span className="mr-2">Not having an account?</span>
            <Link
              to="/signup"
              className="text-blue-600 hover:text-blue-700 font-semibold transition-all duration-300"
            >
              Create a New Account
            </Link>
          </p>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default LoginPage;